<template lang="html">
  <div class="description my-5">
    <div class="description-img d-block d-md-none">
      <img class="ms-img" :src="PipelineSm" alt="" />
    </div>
    <div class="description-img d-none d-md-block">
      <img class="ms-img" :src="Pipeline" alt="" />
    </div>
    <div class="description-text">
      <h3>Clickado ... Why ? How ? What ?</h3>
      <p>
        Many professions are based on repetitivity, and one's proficiency can be
        measured by the ability to quickly repeat a process without errors.
        However, in order to create an optimized process we rely on tools.
        Clickado wants to provide an automation tool that anyone can integrate
        in their already existing workflow.
      </p>
      <p>
        By building a cloud-based software that supports all the main MS-Office
        formats and making it customizable and well documented, we hope to be
        useful to as many professionals as possible, regardless of their
        location and without changing their habits.
      </p>
      <p>
        <span>Clickado</span> is an API that allows to upload, manage and
        populate templates on the cloud. Once the initial setup has been
        completed, template population and the download of documents can be done
        on the go.
      </p>
    </div>
  </div>
</template>

<script setup>
import Pipeline from "@/assets/icons/cad_pipeline.png";
import PipelineSm from "@/assets/icons/cad_pipeline_sm.png";
</script>

<style lang="scss" scoped>
.description {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  margin: 1.5rem 2rem;
  padding: 1.5rem;
  background-color: rgba(255, 255, 255, 0.3);
  border: 1px solid #2c3e50;
  border-radius: 20px;
  max-width: 992px;

  .description-img {
    .ms-img {
      max-width: 100%;
    }
  }
  .description-text {
    text-align: left;
  }
}

@media screen and (min-width: 769px) {
  .description {
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;

    .description-img {
      min-width: 40%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
    }
  }
}
</style>

<template lang="html">
  <div class="api my-5">
    <div class="api-text">
      <h3>A well documented API</h3>
      <p>
        If you feel up to it, you can create your own client following our
        well-documented API. Our documentation uses the
        <code>OpenAPI</code> standard (previously called Swagger)
      </p>
      <button
        class="btn btn-primary"
        v-on:click="goToApi"
        type="button"
        name="button"
      >
        API documentation
      </button>
    </div>
    <div class="api-img">
      <img class="img img-fluid" :src="Api" alt="api-screenshot" />
    </div>
  </div>
</template>

<script setup>
import Api from "@/assets/api.png";

const goToApi = () => {
  window.location.href = "https://api.clickado.org/docs";
};
</script>

<style lang="scss" scoped>
.api {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  padding: 1.5rem;
  background-color: rgba(255, 255, 255, 0.3);
  border: 1px solid #2c3e50;
  border-radius: 20px;
  max-width: 992px;

  .api-img {
    margin-top: 2rem;
    padding: 1rem;
    border: 1px solid rgba(255, 255, 255, 0.3);
  }

  .api-text {
    text-align: left;
  }
}

@media screen and (min-width: 769px) {
  .api {
    flex-direction: row-reverse;
    margin-left: auto;
    margin-right: auto;

    .api-img {
      max-width: 40%;
      margin-right: 40px;
      margin-top: 0;
    }
  }
}
</style>

<template lang="html">
  <div class="container-fluid my-5">
    <h1 class="my-3">
      A customizable <span>templating API</span> for efficiency-focused
      professionals.
    </h1>
  </div>
</template>

<script setup>
console.log("bar");
</script>

<style lang="scss" scoped>
span {
  color: #e1e1e1;
}
@media screen and (max-width: 767px) {
  span {
    display: block;
  }
}
</style>

<template lang="html">
  <div class="client my-5">
    <div class="client-text">
      <h3>Try it out with our sample client</h3>
      <p>
        Since we know that not everyone is tech-savy we offer a sample client
        that allows you to use Clickado.
      </p>
      <p>
        If you are a front-end developer it can be complicated to make a
        decision without
      </p>
      <button
        class="btn btn-primary"
        v-on:click="goToClient"
        type="button"
        name="button"
      >
        Try sample client
      </button>
    </div>
    <div class="client-img">
      <img class="img img-fluid" :src="Client" alt="Client-screenshot" />
    </div>
  </div>
</template>

<script setup>
import Client from "@/assets/client.png";

const goToClient = () => {
  window.location.href = "https://client.clickado.org";
};
</script>

<style lang="scss" scoped>
.client {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  border: 1px solid #2c3e50;
  border-radius: 20px;
  padding: 1.5rem;
  max-width: 992px;

  .client-img {
    margin-top: 2rem;
    padding: 1rem;
    border: 1px solid rgba(255, 255, 255, 0.3);
  }

  .client-text {
    text-align: left;
  }
}

@media screen and (min-width: 769px) {
  .client {
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;

    .client-img {
      margin-top: 0;
      margin-left: 40px;
      max-width: 40%;
    }
  }
}
</style>

<template lang="html">
  <div class="hero">
    <ul>
      <li class="hero-element clickado-font">Clickado</li>
      <li class="hero-element">
        <p>Version</p>
        <span class="clickado-font">0.0.1</span>
      </li>
      <li class="hero-element">
        <button
          class="btn btn-primary hero-btn"
          v-on:click="goToApi"
          type="button"
          name="button"
        >
          API
        </button>
      </li>
      <li class="hero-element">
        <button
          class="btn btn-primary hero-btn"
          v-on:click="goToClient"
          type="button"
          name="button"
        >
          Client
        </button>
      </li>
    </ul>
  </div>
</template>

<script setup>
const goToApi = () => {
  window.location.href = "https://api.clickado.org";
};
const goToClient = () => {
  window.location.href = "https://client.clickado.org";
};
</script>

<style lang="scss" scoped>
.hero {
  margin-top: 1rem;
  width: 250px;
  ul {
    background-color: rgba(255, 255, 255, 0.3);
    display: flex;
    flex-direction: column;
    list-style: none;
    border: 1px solid #2c3e50;
    border-radius: 20px;
    padding: 0;
    margin: 0;

    .hero-element {
      border-top: 1px solid #2c3e50;
      padding: 1rem;

      &:first-of-type {
        font-size: 40px;
        padding: 2rem 1rem;
        border-top: none;
      }

      p {
        font-size: 11px;
        margin: 0;
        padding: 0;
      }
      span {
        font-size: 20px;
      }
    }
    .hero-btn {
      min-width: 150px;
      height: 50px;
    }
  }
  .clickado-font {
    font-family: "cream", Helvetica, Arial;
  }
}
</style>

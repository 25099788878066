<template lang="html">
  <div class="logo">
    <img :src="Logo" class="clickado-logo img-fluid" alt="clickado-logo" />
  </div>
</template>

<script setup>
import Logo from "@/assets/clickado_logo.svg";
</script>

<style lang="scss" scoped>
.logo {
  max-width: 250px;
}
@media screen and (min-width: 768px) {
  .logo {
    max-width: 300px;
  }
}
</style>
